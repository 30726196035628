import {ApiRequestConfig} from "~/core-ui/ts/request/AbstractApiRequest";
import LcabApiRequest from "~/cabinet/ts/api/LcabApiRequest";

export default class TildaSiteApiRequest<DataType> extends LcabApiRequest<DataType> {

    protected getUrl(path: string): string {
        return path;
    }


    protected logError(options: ApiRequestConfig, result: any, message: string = null) {

    }
}

