import Dictionary from "~/ts/library/Dictionary";
import Vue from "vue";
import TarifCalculator from "~/site/talk-me/vue/tarifCalculator/TarifCalculator.vue";
import RegistrationPopup from "~/site/talk-me/vue/registrationPopup/RegistrationPopup.vue";

import "~/site/talk-me/scss/index.scss";
import initElementUI from "~/site/talk-me/ts/InitElementUI";

document.addEventListener('DOMContentLoaded', () => {
    const componentList: Dictionary<any> = {
        'tarif-calculator': TarifCalculator,
        'registration-popup-form': RegistrationPopup
    };

    let first = true;

    for (let id in componentList) {
        if (componentList.hasOwnProperty(id)) {
            let element = document.getElementById(id);
            if (element) {
                if (first) {
                    initElementUI();
                    first = false;
                }
                let app = new Vue(componentList[id]);
                let child = document.createElement("div");
                element.appendChild(child);
                element.classList.add("vue-root");
                app.$mount(child);
            }
        }
    }
});
