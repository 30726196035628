import Dictionary from "~/ts/library/Dictionary";

let div = document.createElement("div");

const BBCODE_BOLD = "bold";
const BBCODE_ITALIC = "italic";
const BBCODE_UNDERLINE = "underline";
const BBCODE_STRIKE_THROUGH = "strike-through";
const BBCODE_LINK = "link";
const BLANK_PLACEHOLDER = "[%TARGET_BLANK%]";



class StringHelper {
    public static numberFormat(value: number): string {
        let formatter = new Intl.NumberFormat('ru-RU', {
            minimumFractionDigits: value % 1 === 0 ? undefined : 2
        });
        return formatter.format(value);
    }
    public static capitalize(txt: string): string {
        return txt.charAt(0).toUpperCase() + txt.slice(1);
    }

    public static replaceVariables(text: string, variables: Dictionary<string>) {
        for (let key in variables) {
            if (variables.hasOwnProperty(key)) {
                text = text.split(`[${key}]`).join(variables[key]);
            }
        }
        return text;
    }

    public static addSlashes(str: string): string {
        var a: any = {};
        a[str] = 1;
        return JSON.stringify(a).slice(2, -4);
    }

    //Очень аккуратно. Пихай сюда только то, что уже было в HTML, можно нарваться на XSS!
    public static htmlToText(str: string): string {
        div.innerHTML = str;
        return div.innerText;
    }

    public static readonly bbcodeList: Dictionary<{
        toolbar: {
            name: string,
            delimiter?: boolean
        },
        fromHtml: {
            selector: string,
            getHtml: (element: HTMLElement) => string
        },
        fromBbCode: {
            regexp: RegExp,
            replacerToHtml: (substring: string, ...args: any[]) => string,
            stripBbCodeReplacer: (substring: string, ...args: any[]) => string
        }
    }> = {
        [BBCODE_BOLD]: {
            toolbar: {
                name: "bold"
            },
            fromHtml: {
                selector: "b, strong",
                getHtml: (element: HTMLElement) => {
                    return `[{b}]${element.innerHTML}[{/b}]`
                }
            },
            fromBbCode: {
                regexp: /\[\{?b}?](.*?)\[\{?\/b}?]/gis,
                replacerToHtml: (match, text) => {
                    let strong = document.createElement("strong");
                    strong.innerHTML = StringHelper.bbcodeToHtml(text, true);
                    return strong.outerHTML;
                },
                stripBbCodeReplacer: (match, text) => {
                    return StringHelper.stripBbCode(text);
                }
            }
        },
        [BBCODE_ITALIC]: {
            toolbar: {
                name: "italic"
            },
            fromHtml: {
                selector: "i,em",
                getHtml: (element: HTMLElement) => {
                    return `[{i}]${element.innerHTML}[{/i}]`
                }
            },
            fromBbCode: {
                regexp: /\[\{?i}?](.*?)\[\{?\/i}?]/gis,
                replacerToHtml: (match, text) => {
                    let strong = document.createElement("em");
                    strong.innerHTML = StringHelper.bbcodeToHtml(text, true);
                    return strong.outerHTML;
                },
                stripBbCodeReplacer: (match, text) => {
                    return StringHelper.stripBbCode(text);
                }
            }
        },
        [BBCODE_UNDERLINE]: {
            toolbar: {
                name: "underline"
            },
            fromHtml: {
                selector: "span[style*='text-decoration: underline;']",
                getHtml: (element: HTMLElement) => {
                    return `[{u}]${element.innerHTML}[{/u}]`
                }
            },
            fromBbCode: {
                regexp: /\[\{?u}?](.*?)\[\{?\/u}?]/gis,
                replacerToHtml: (match, text) => {
                    let span = document.createElement("span");
                    span.innerHTML = StringHelper.bbcodeToHtml(text, true);
                    span.setAttribute("style", "text-decoration: underline;");
                    return span.outerHTML;
                },
                stripBbCodeReplacer: (match, text) => {
                    return StringHelper.stripBbCode(text);
                }
            }
        },
        [BBCODE_STRIKE_THROUGH]: {
            toolbar: {
                name: "strikethrough"
            },
            fromHtml: {
                selector: "span[style*='text-decoration: line-through;']",
                getHtml: (element: HTMLElement) => {
                    return `[{s}]${element.innerHTML}[{/s}]`
                }
            },
            fromBbCode: {
                regexp: /\[\{?s}?](.*?)\[\{?\/s}?]/gis,
                replacerToHtml: (match, text) => {
                    let span = document.createElement("span");
                    span.innerHTML = StringHelper.bbcodeToHtml(text, true);
                    span.setAttribute("style", "text-decoration: line-through;");
                    return span.outerHTML;
                },
                stripBbCodeReplacer: (match, text) => {
                    return StringHelper.stripBbCode(text);
                }
            }
        },
        [BBCODE_LINK]: {
            toolbar: {
                name: "link unlink",
                delimiter: true
            },
            fromHtml: {
                selector: "a",
                getHtml: (a: HTMLLinkElement) => {
                    return `[{url=${a.href}}]${a.innerHTML}[{/url}]`
                }
            },
            fromBbCode: {
                regexp: /\[\{?url=(.*?)}+?](.*?)\[\{?\/url}?]/gis,
                replacerToHtml: (match, url: string, text) => {
                    let a = document.createElement("a");
                    let blankInUrl = url.startsWith(BLANK_PLACEHOLDER);
                    if (blankInUrl) {
                        url = url.substring(BLANK_PLACEHOLDER.length);
                    }
                    div.innerHTML = url;
                    a.setAttribute("href", div.innerText);
                    let blank = true;
                    if (a.protocol && a.hostname && (a.protocol == window.location.protocol && a.hostname == window.location.hostname)) {
                        blank = false;
                    }
                    if (blank || blankInUrl) {
                        a.setAttribute("target", "_blank");
                    }
                    a.innerHTML = this.bbcodeToHtml(text, true);
                    return a.outerHTML;
                },
                stripBbCodeReplacer: (match, url, text) => {
                    return StringHelper.stripBbCode(`(${url})${text}`);
                }
            }
        }
    }

    public static textToHtml(str: string): string {
        div.innerText = str;
        return div.innerHTML;
    }

    public static stripBbCode(str: string): string {
        for (let key in this.bbcodeList) {
            if (this.bbcodeList.hasOwnProperty(key)) {
                let item = this.bbcodeList[key].fromBbCode;
                str = str.replace(item.regexp, item.stripBbCodeReplacer);
            }
        }
        return str;
        //return this.htmlToText(this.bbcodeToHtml(str, true)).split("\n").join(" \n");
    }

    public static bbcodeToHtml(value: string, recursive: boolean = false): string {
        if (!recursive) {
            value = this.textToHtml(value);
        }
        for (let key in this.bbcodeList) {
            if (this.bbcodeList.hasOwnProperty(key)) {
                let item = this.bbcodeList[key].fromBbCode;
                value = value.replace(item.regexp, item.replacerToHtml);
            }
        }

        return value;
    }

    public static htmlToBbcode(value: string): string {
        div.innerHTML = value;
        for (let key in this.bbcodeList) {
            if (this.bbcodeList.hasOwnProperty(key)) {
                let item = this.bbcodeList[key].fromHtml;
                this.applyHtmlToBbcode(div, item.selector, item.getHtml);
            }
        }
        this.applyHtmlToBbcode(div, "br", () => "\n");
        return this.htmlToText(div.innerHTML);
    }

    private static applyHtmlToBbcode(div: HTMLDivElement, selector: string, getHtml: (element: HTMLElement) => string) {
        while (true) {
            let element = div.querySelector(selector) as HTMLElement;
            if (element) {
                let newSpan = document.createElement("span");
                newSpan.innerHTML = getHtml(element);
                let parent = element.parentElement;
                parent.replaceChild(newSpan, element);
            } else {
                break;
            }
        }
    }

    public static isIP(ipaddress: string): boolean {
        return (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress));
    }

    public static toKebab(string: string): string {
        return string
            .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
            .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
            .toLowerCase();
    }

    public static substr(f_string: string, f_start: number, f_length: number): string {
        f_string = f_string.toString();
        if (f_start < 0) {
            f_start += f_string.length;
        }
        if (f_length == undefined) {
            f_length = f_string.length;
        } else if (f_length < 0) {
            f_length += f_string.length;
        } else {
            f_length += f_start;
        }
        if (f_length < f_start) {
            f_length = f_start;
        }
        return f_string.substring(f_start, f_length);
    }

    public static getSclon(n: number, i: string, r: string, rm: string): string {
        var res: string;
        var lastD = parseInt(StringHelper.substr(n.toString(), -1, 1));

        if (n > 20) {
            if (lastD > 1 && lastD < 5)
                res = r;
            else if (lastD == 1)
                res = i;
            else
                res = rm;
        } else if (n == 1) {
            res = i;
        } else if ((n > 4 && n <= 20) || n == 0) {
            res = rm;
        } else {
            res = r;
        }

        return res;
    }

    public static htmlEntities(str: string) {
        return String(str)
            .replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;');
    }

    public static parseRGB(rgb: string): string[] {
        return rgb.replace(/[^\d,]/g, '').split(',');
    }

    public static round(number: number, precision: number = 2) {
        if (number % 1) {
            number = number.toFixed(precision) as any;
        }
        return number;
    }

}

export default StringHelper;