import AbstractDelay from "./AbstractDelay";

class Timeout extends AbstractDelay {
    protected clearMethod: (id: number) => void = clearTimeout;
    protected setMethod: (callback: () => void, delay: number) => number = setTimeout;


    set(callback: () => void, delay: number): number {
        let that = this;
        let id: number;
        let newCallback = function () {
            that.clear(id);
            callback();
        };
        return super.set(newCallback, delay);
    }
}


export const Timeouts = new Timeout();