import StringHelper from "./StringHelper";
import Punycode from "./Punycode";
import Random from "~/ts/library/Random";
import Dictionary from "~/ts/library/Dictionary";

export default class Cookie {
    public static unset(name: string, path?: string, domain?: string) {
        Cookie.set(name, "", -86400, path, domain);
    }

    public static set(name: string, value: string, ttl: number = 0, path?: string, domain?: string, secure: boolean = false) {
        if (path == null) {
            path = "/";
        }
        let domainObject: string[];
        if (domain == null) {
            domain = window.location.hostname;
            if (StringHelper.isIP(domain)) {
                domainObject = [domain];
            } else {
                let count = 2;
                let checkName = Random.uid(10);
                let resultDomain: string;
                while (Cookie.get(checkName) == null) {
                    let domainArray = domain.split(".");
                    resultDomain = domainArray.slice(domainArray.length - count).join(".");
                    count++;
                    Cookie.set(checkName, "1", 60000, path, resultDomain);
                    if (count > domain.length) {
                        break;
                    }
                }
                Cookie.unset(checkName, path, resultDomain);
                domainObject = [resultDomain];
            }
        } else if (typeof domain !== "object") {
            domainObject = [domain]
        }

        let date = new Date();
        if (!ttl) {
            let u = 10;
            ttl = u * 24 * 60 * 60 * 1e3;
        }
        date.setTime(date.getTime() + ttl);
        let expire = date.toUTCString();
        let a = "";
        for (let f = domainObject.length - 1; f >= 0; f--) {
            if (StringHelper.isIP(domainObject[f]) || domainObject[f].toLowerCase() == "localhost") {
                a = domainObject[f];
            } else {
                a = "." + domainObject[f]/* + a*/;
            }
            a = Punycode.toAscii(a);

            document.cookie = name + "=" + escape(value) + (expire ? "; expires=" + expire : "") + (path ? "; path=" + path : "") + (domain ? "; domain=" + a : "") + (secure ? "; secure" : "") + "; SameSite=Lax";
        }
    }

    public static get(name: string) {
        return this.getAll()[name];
        /*
        let t = " " + document.cookie;
        let n = " " + name + "=";
        let r = null;
        let i = 0;
        let s = 0;
        if (t.length > 0) {
            i = t.indexOf(n);
            if (i !== -1) {
                i += n.length;
                s = t.indexOf(";", i);
                if (s === -1) {
                    s = t.length
                }
                r = unescape(t.substring(i, s))
            }
        }
        return r
        */
    }

    public static getAll(): Dictionary<string> {
        return Object.fromEntries(document.cookie.split('; ').map(c => c.split('=')))
    }
}
