import Vue from "vue";
import Switch from "element-ui/lib/switch.js";
import Radio from "element-ui/lib/radio.js";
import Button from "element-ui/lib/button.js";
import Slider from "element-ui/lib/slider.js";
import InputNumber from "element-ui/lib/input-number.js";
import Form from "element-ui/lib/form.js";
import FormItem from "element-ui/lib/form-item.js";
import Input from "element-ui/lib/input.js";
import Checkbox from "element-ui/lib/checkbox.js";

export default function initElementUI() {
    Vue.component("el-switch", Switch);
    Vue.component("el-radio", Radio);
    Vue.component("el-button", Button);
    Vue.component("el-slider", Slider);
    Vue.component("el-input", Input);
    Vue.component("el-checkbox", Checkbox);
    Vue.component("el-input-number", InputNumber);
    Vue.component("el-form", Form);
    Vue.component("el-form-item", FormItem);
}